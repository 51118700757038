import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


export default function Hydrafaq({setIsModalOpen}) {
    return (
        <Section py="4" id="faq" bg="#ffffff" className="pb-md-5 mb pt-md-5 ">
          <Container className="pb-md-5 mb pt-md-5 pt-3 pb-3">
            <Row className="justify-content-center pb-4">
              <Col lg="12">

                <Iwrap>


                  <Itext as="h2">More about <span>HydraFacial</span></Itext>
                </Iwrap>
                <SecondText>
                  Frequently Asked Questions

                </SecondText>

              </Col>
            </Row>


            <Row className="">
              <Col

              >
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Will I have a consultation before a treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      Yes. We start the appointment with a thorough consultation to understand your requirements and
                      suitability. If you book in for a treatment, the consultation and treatment will take place on the
                      same day once we have a full assessment and you are happy to proceed.
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion> <br />


                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        How do I know which HydraFacial is the right one for me?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      If you are unsure of which facial treatment to select, we advise you book in for a Skin
                      Consultation with our specialist prior to scheduling a treatment. Our expert team will analyse
                      your skin and work together with you to understand your current skin concerns as well as your
                      desired outcomes from the skin treatments. We will then be able to recommend the finest treatment
                      all tailored to your skin concerns.
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>

                <br />


                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Are there any age restrictions for treatments at Dermamina?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      All patients receiving any type of treatment at Dermamina must be aged 18 or over. We will require
                      proof of ID if aged 19 and younger.
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>


                <br />


                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Why choose Dermamina for HydraFacial?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      We are renowned for providing natural looking results all tailored to the beauty of our
                      patient. <br /> <br />

                      At Dermamina, we like to go the extra mile by incorporating the multi award winning Dermalux LED
                      Phototherapy to finish off the Platinum HydraFacial range. Unlike any other standard HydraFacial,
                      your HydraFacial will be boosted with one of the most powerful LED systems in the world, with
                      clinically proven wavelengths.

                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>

                <br />

                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What if I decide to not go ahead or go for another facial treatment after the consultation?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      The consultation is designed to understand the best and most suitable treatment for you, so we
                      will not go ahead with a treatment if it is not needed or if you decide you do not want to go
                      ahead with it. <br /> <br />

                      If a different, more suitable facial is decided during the consultation then we can change it on
                      the day and go for the chosen treatment instead.<br /> <br />

                      If you wish to end the appointment with a consultation only, you will only be charged the
                      consultation fee.

                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>

              </Col>


            </Row>


            <br /> <br />
            <div onClick={() => setIsModalOpen(true)}
                 activeClassName="active"
            >
              <Bookbutton>
                Book Appointment
              </Bookbutton>
            </div>


          </Container>
        </Section>


    );
}